import CMFilterDTO from '../DTO/cm-filters.dto';
import { FILTERS_ARGS } from '../cm-const';

export class CMFiltersModel {

  constructor(filters = {}) {
    this.filters = new CMFilterDTO(filters);
  }

  getApiData() {
    return {
      [FILTERS_ARGS.SEARCH]: (this.filters.search && this.filters.search.length) ? this.filters.search : undefined,
      [FILTERS_ARGS.EXAM_DATE_BEFORE]: this.filters.examDateBefore,
      [FILTERS_ARGS.EXAM_DATE_AFTER]: this.filters.examDateAfter,
      [FILTERS_ARGS.EXAM_LOCATION]: this.filters.examLocation,
      [FILTERS_ARGS.CBR_USER]: this.filters.cbr_user,
      [FILTERS_ARGS.COURSE_TYPE]: this.filters.course_type
    };
  }
}
