import { getMultipleQueryValueWithNumber, transformQueryBoolean } from "@/util";
import { FILTERS_ARGS } from '../cm-const';

export default class CMFilterDTO {
  constructor(queries = {}) {
    this.search = queries[FILTERS_ARGS.SEARCH];
    this.examDateBefore = queries[FILTERS_ARGS.EXAM_DATE_BEFORE];
    this.examDateAfter = queries[FILTERS_ARGS.EXAM_DATE_AFTER];
    this.examLocation = queries[FILTERS_ARGS.EXAM_LOCATION];
    this.cbr_user = getMultipleQueryValueWithNumber(queries[FILTERS_ARGS.CBR_USER]);
    this.refunded = queries.refunded ? transformQueryBoolean(queries.refunded) : null;
    this.course_type = queries[FILTERS_ARGS.COURSE_TYPE];
  }
}
