<template lang="pug">
  .filters
    portal(:to="portalChipName")
      cm-filters-chips(:model="model" :portal-chip-name="portalChipName" @remove="removeFilter" @remove:multiple="removeFilterMultiple")
    cm-filters-list(:filters="filters" @filterChanged="startFiltering")
</template>

<script>
import { CMFiltersModel } from '../../core/models/cm-filters.model';

export default {
  props: {
    portalChipName: String
  },

  data: () => ({
    model: {},
    filters: {
      cbr_user: null,
      examLocation: '',
      examDateBefore: '',
      examDateAfter: ''
    }
  }),

  inject: ['getDefaultPagination'],

  created() {
    this.initFilters()
  },

  methods: {
    initFilters() {
      this.model = new CMFiltersModel(this.$route.query);
      this.setFilters();
    },

    async startFiltering() {
      this.setFiltersToModel();
      await this.setQuery();
    },

    setFiltersToModel() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.model.filters[filter] = this.filters[filter]
        }
      }
    },

    async setQuery(params) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...this.model.getApiData(), ...params}});
      this.$emit('filterChanged');
    },

    setFilters() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.filters[filter] = this.model.filters[filter]
        }
      }
    },

    removeFilter(field) {
      this.filters[field] = undefined;
      this.model.filters[field] = undefined;
      this.setQuery()
    },

    removeFilterMultiple(field) {
      this.filters[field] = [];
      this.model.filters[field] = [];
      this.setQuery()
    },
  },

  components: {
    CmFiltersList: () => import('./CMFiltersList.vue'),
    CmFiltersChips: () => import('./CMFiltersChips.vue'),
    AppSelect: () => import('@/components/global/actions/BaseSelect.vue')
  }
}
</script>
